
function logHeaderHeight() {
    const headerMain = document.querySelector('.header.header--main');
    const gutentocOllist = document.querySelector('.gutentoc.ollist');
    const BlogDetailRightWrap = document.querySelector('.blog-detail-right-wrap');
    if (headerMain) {
        const headerHeight = headerMain.offsetHeight;
        if (gutentocOllist) {
            gutentocOllist.style.top = `${headerHeight + 20}px`;
        }
        if (BlogDetailRightWrap) {
            BlogDetailRightWrap.style.top = `${headerHeight + 20}px`;
        }
    }
}

window.addEventListener('load', logHeaderHeight);
window.addEventListener('scroll', logHeaderHeight);
window.addEventListener('resize', logHeaderHeight);

// Hire Developer Close Icon 
var closeIcon = document.querySelector('.hire-dev-main .close-icon');
var hireDev = document.querySelector('.hire-dev');
if (closeIcon && hireDev) {
    closeIcon.addEventListener('click', function () {
        fadeOut(hireDev);
    });
}
function fadeOut(element) {
    var opacity = 1;
    var fadeEffect = setInterval(function () {
        if (opacity <= 0) {
            clearInterval(fadeEffect);
            element.style.display = 'none';
        } else {
            opacity -= 0.1;
            element.style.opacity = opacity;
        }
    }, 170);
}
