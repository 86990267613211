import Splide from "@splidejs/splide";

// Technology slider
if (document.querySelector('.technology--slider')) {

    if (window.innerWidth <= 640)
        intializetechnologylider();

    window.addEventListener('resize', function () {
        if (window.innerWidth <= 640) {
            intializetechnologylider(true);
        } else {
            intializetechnologylider(true, true);
        }

    });

}

function intializetechnologylider(resize = false, destroy = false) {
    var sliderselector = document.getElementsByClassName('technology--slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const technologylider = new Splide(slide_ele, {
            type: "slide",
            arrows: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            pagination: true,
            breakpoints: {
                640: {
                    destroy: false,
                },
            },
        });
        technologylider.mount();
        if (true === resize)
            technologylider.refresh();
        if (true === destroy)
            technologylider.destroy();

    }
}