import Splide from "@splidejs/splide";

// Testimonial List slider
if (document.querySelector('.wordpress-badges-slider')) {
    var sliderselector = document.getElementsByClassName('wordpress-badges-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const servicesSlider = new Splide(slide_ele, {
            type: "loop",
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 2500,
            speed: 2500,
            easing: 'linear',
            perPage: 1,
            gap: 21,
            autoWidth: true,
        });
        servicesSlider.mount();
    }
}